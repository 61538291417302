/* @media only screen and (min-width: 768px) { */
:root {
    --ec-weekend: rgba(0, 0, 0, 0); /*Provide weekends border*/
    --ec-light: #ffffff;
    --ec-primary: gray;
    --ec-secondary: #a87777;
    --ec-dark: gray;

    /*if options pattern is alternate*/
    --ec-alternate-bg:#dbd8f0;
    --ec-alternate-bg-light:#eceaf5;

    /*Background of day name background, two different color will give alternate colors bg*/
    --ec-title-bg-light: #f1f1f1;
    --ec-title-bg: #f1f1f1;

    /*Only for title / header section*/
    --ec-title: gray;
    --ec-header: #ffffff;

    /*main bg of each cell*/
    --ec-bg-main: #ffffff;
    
    
    --ec-today: #a35353; 
    --ec-event:#eedf16; /*event day color background*/
    --ec-disabled:#c2c1cc; /*color of disabled cells*/
    --ec-height: 60px; /*height of each day cell*/
    --ec-mainWidth:100%; /*Determines the width of the main container*/
    --ec-calendarWidth:100%; /* set the width only for days and week names section */
    --ec-border:#f0f0f0; 


    /*if accessibility is true, these selected and selected-text variables will reflect*/ 
    --ec-selected:#8072d0; 
    --ec-selected-text:#ffffff;

    --ec-tooltipWidth: 250px; /*Width of tooltip*/
    --ec-tooltipHeight:200px; /*Height of tooltip*/
    --ec-tooltip-bg:#fff; /*Tooltip background color*/
    --ec-tooltip-fg:#0b386c; /*Tooltip text (foreground) color*/
    --ec-tooltip-title:18px; /*Tooltip title font size*/
    --ec-tooltip-list:16px; /*Tooltip list font size*/
    --ec-tooltip-border-radius: 8px; /*Border radius for tooltip box*/


    
}
/* } */

@media only screen and (max-width: 768px) {
    :root {
      --ec-height: 60px; 
      --ec-mainWidth:100%; 
      --ec-calendarWidth:100%;

      
    }
  
  }
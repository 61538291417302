@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';

@import 'primereact/resources/themes/lara-light-blue/theme.css';
@import 'primereact/resources/primereact.css';
@import 'primeicons/primeicons.css';

@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.p-calendar .p-inputtext {
  font-size: 13px;
}

.p-datatable .p-datatable-tbody {
  font-size: 11.5px !important;
}

.p-multiselect-item {
  font-size: 11.5px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: transparent;
  background: #03045e;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #03045e;
  color: white;
  font-weight: 600;
}

:root {
  --popup-left: 60%; /* Default left position */
}

.p-confirm-popup {
  position: fixed;
  top: 50%;
  left: var(--popup-left);
  transform: translate(-50%, -50%);
  margin-top: 20px;
}

@media (max-width: 768px) {
  :root {
    --popup-left: 50%; /* Adjust left position for smaller screens */
  }
}

.scrollbar-table {
  height: 475px;
  overflow: auto;
  position: relative;
}

::-webkit-scrollbar {
  width: 5%;
}

::-webkit-scrollbar-thumb {
  border-radius: 100vw;
}

.scrollbar-table thead {
  position: sticky;
  top: 0;
}

.p-datatable-header {
  height: 70px;
}
